.link {
	position: absolute;
	inset: 0;
	z-index: 1;
}

.icon {
	display: flex;
	height: 100%;
	color: var(--token-color-foreground-secondary);
}

.cta {
	position: relative;
	z-index: 2;
	margin-top: auto;
}

.content {
	display: grid;
	grid-template-rows: max-content auto;
	border-radius: inherit;
	height: 100%;
}

.subheading {
	margin-top: calc(-1 * var(--hdsplus-spacing-04));
}

.contentLockup {
	display: flex;
	flex-direction: column;
	padding: var(--hdsplus-spacing-07);
	gap: var(--hdsplus-spacing-04);
}

.thumbnail {
	position: relative;
	aspect-ratio: var(--aspect-ratio, 1/1);
	border-top-left-radius: inherit;
	border-top-right-radius: inherit;
	overflow: hidden;
	& img {
		object-fit: cover;
	}
}

.badges {
	display: flex;
	flex-wrap: wrap;
	gap: var(--hdsplus-spacing-02);
}

.meta {
	color: var(--token-color-foreground-faint);
	text-transform: uppercase;
	margin: 0;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	padding: 0;
}

.metaSeparator {
	--padding-x: 12px;

	padding-left: var(--padding-x);
	padding-right: var(--padding-x);
}
